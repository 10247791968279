<template>
  <div class="relative h-50 lg:h-81 aspect-3/2 md:aspect-16/5 lg:aspect-16/9 w-full">
    <div class="absolute inset-0 z-1 bg-wrapper overflow-hidden">
      <div class="relative w-full h-full">
        <div class="absolute top-5 -left-20 h-full">
          <img src="/images/circle/hinh12.svg" class="object-cover w-full h-full" alt="Banner Pattern"/>
        </div>
        <div class="absolute top-0 -right-40 h-full">
          <img src="/images/circle/hinh13.svg" class="object-cover w-full h-full" alt="Banner Pattern"/>
        </div>
      </div>
    </div>
    <div class="relative z-10 container h-full flex justify-start">
      <h1
          data-aos="fade-right"
          data-aos-once="true"
          class="text-3xl md:text-56px font-[550] text-white leading-10 lg:leading-17 mt-auto pb-10 xl:pb-22"
      >
        {{ $t('INSURANCE_PRODUCTS2') }}
      </h1>
    </div>
  </div>
</template>
<script setup lang="ts"></script>
<style scoped>
.bg-wrapper {
  background: radial-gradient(247.42% 100.06% at 32.37% 100%, #ffa17d 5.03%, #ff8050 42.75%, #ed5a22 100%);
}
</style>
