<template>
  <div
      class="relative search-select-product !bg-white !m-0 flex-lazy-row-center cursor-pointer group h-full"
      v-on-click-outside="onClickOutsideHandler"
  >
    <div @click="statusDropdown = true" class="w-full py-1px">
      <p v-if="currentValue && currentValue.length == 1" class="text-bw-07 line-clamp-1 text-sm font-medium">
        {{ currentValue[0]?.title }}
      </p>

      <div v-else-if="currentValue && currentValue.length > 1" class="flex items-center gap-1 font-medium">
        <div
            class="flex items-center text-shade-black-06 px-3 py-2.5 rounded-50px border-1 border-bw-10 bg-[#f6f6f6] text-xs flex-1"
        >
          <p class="line-clamp-1 flex-1">{{ currentValue[currentValue.length - 1]?.title }}</p>
          <div
              @click="removeItemCompany"
              class="flex-none i-custom-close text-bw-15 w-5 h-5 cursor-pointer hover:text-bw-15/80"
          ></div>
        </div>
        <div
            class="w-10 h-10 rounded-full flex-lazy-col-center text-shade-black-06 border-1 border-bw-10 bg-[#f6f6f6] text-sm"
        >
          +{{ currentValue.length - 1 }}
        </div>
      </div>
      <p v-else class="text-black/30 group-hover:text-bw-07/80 text-sm font-medium duration-200">
        {{ placeholder }}
      </p>
    </div>
    <span
        @click="statusDropdown = true"
        v-if="currentValue && currentValue.length < 1"
        class="i-ic:twotone-keyboard-arrow-down absolute w-5 h-5 text-black/30 right-4 group-hover:text-bw-07 duration-200"
    ></span>
    <Transition name="nestedSelect">
      <PopupSelected
          v-if="statusDropdown"
          v-model="currentValue"
          @closeDropdown="handleCloseDropdown"
          :data="dataFilter"
          :class="positionRight ? 'right-0' : 'left-0'"
          class="absolute top-full mt-2 lg:mt-7 w-full lg:w-120 rounded-md z-10"
      />
    </Transition>
  </div>
</template>

<script setup lang="ts">
import PopupSelected from './PopupSelectedProduct.vue'
import {vOnClickOutside} from '@vueuse/components'

const props = defineProps({
  dataFilter: {
    type: Array
  },
  placeholder: {
    type: String
  },
  positionRight: {
    type: Boolean
  }
})
const currentValue: any = defineModel()
const statusDropdown = ref(false)
const onClickOutsideHandler = () => {
  statusDropdown.value = false
}
const handleCloseDropdown = (status: boolean) => {
  statusDropdown.value = status
}
const removeItemCompany = () => {
  const companyTemp: any = ref(currentValue.value.pop())
  currentValue.value = currentValue.value.filter((item: any) => item.id !== companyTemp.value.id)
}
</script>

<style></style>
