<template>
  <banner-index></banner-index>
  <section>
    <div class="container mt-5 lg:mt-20">
      <FilterProduct @dataSearch="changeData"/>
      <div
          v-if="dataRender?.model && dataRender?.model.length === 0"
          class="relative h-20 flex flex-col items-center justify-center"
      >
        <p class="text-2xl text-center text-bw-06">
          {{ $t('NO_DATA_PRODUCT') }}
        </p>
        <PopupLoadingFilter v-if="loadingFilter && dataRender?.model.length === 0"/>
      </div>
      <div
          data-aos="zoom-in"
          data-aos-once="true"
          data-aos-offset="-500"
          class="relative grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5"
      >
        <template
            v-if="dataRender?.model && dataRender?.model.length > 0"
            v-for="item in dataRender?.model"
            :key="item?.id"
        >
          <GlobalBoxCardProduct :item="item"/>
          <PopupLoadingFilter v-if="loadingFilter"/>
        </template>
      </div>
      <button
          v-if="checkFilterCount && checkFilterCount > 0 && checkFilterCount > 9 && checkFilterCount > LIMIT"
          @click="showMoreProducts"
          class="relative flex items-center justify-center gap-1 btn-effect text-white bg-primary font-semibold uppercase text-sm px-11.5 rounded-30px py-3 mt-10 mx-auto min-w-50 min-h-12"
      >
        <div v-if="!loadingFilter" class="flex items-center justify-center gap-1">
          <p>{{ t('SHOW_MORE') }}</p>
          <span class="i-ic:twotone-keyboard-arrow-down text-white text-xl"></span>
        </div>
        <div v-else class="absolute loaderForm"></div>
      </button>
    </div>

    <BannerBottom class="mt-10 lg:mt-26"/>
  </section>
</template>

<script setup lang="ts">
import FilterProduct from './components/FilterProducts.vue'
import BannerBottom from './components/BannerBottom.vue'
import BannerIndex from './components/BannerIndex.vue'
import PopupLoadingFilter from './components/PopupLoadingFilter.vue'
import {useProducts} from '../../composables/products/index'
import {useGetLangCurrent} from '../../stores/useLanguageMenu'

const {searchProducts} = useProducts()
const {t} = useI18n()

const props = defineProps({
  dataSeach: {
    type: Array
  }
})
const store = useGetLangCurrent()
const LIMIT = ref(9)

const route = useRoute()
const dataRender = ref({
  model: []
})

const checkFilterCount = ref(0)
const loadingFilter = ref(false)
const infoFilter = ref({})

const fetchProds = async (query: any) => {
  // let checkBenefit
  // if (typeof query.benefit == 'string') {
  //     if (query.benefit.includes('&')) {
  //         checkBenefit = query.benefit.split('&')
  //     } else {
  //         checkBenefit = [query.benefit]
  //     }
  // } else {
  //     checkBenefit = query.benefit
  // }

  LIMIT.value = 9
  loadingFilter.value = true
  const res: any = await searchProducts({
    text: query?.text,
    carrier: query?.carrier,
    category: query?.category,
    benefit: query?.benefit,
    object: query?.object,
    utilities: query?.utilities,
    price_range: query?.price_range_object ? query?.price_range_object : query?.price_range,
    lang: route.params.langCode
  }).catch((err) => console.log(err))
  infoFilter.value = {
    text: query?.text,
    carrier: query?.carrier,
    category: query?.category,
    benefit: query?.benefit,
    object: query?.object,
    utilities: query?.utilities,
    price_range: query?.price_range_object ? query?.price_range_object : query?.price_range,

    lang: route.params.langCode
  }
  dataRender.value.model = res.products
  checkFilterCount.value = res.filter_count
  loadingFilter.value = false
}

const changeData = async (data: any) => {
  infoFilter.value = {
    text: data?.text,
    carrier: data.carrier,
    category: data.category,
    benefit: data?.benefit,
    object: data?.object,
    utilities: data?.utilities,
    price_range: data.price_range,
    lang: route.params.langCode
  }

  await fetchProds(infoFilter.value)
}

const showMoreProducts = async () => {
  loadingFilter.value = true

  const data: any = await searchProducts(infoFilter.value, {limit: (LIMIT.value += 9)})

  dataRender.value.model = data.products
  loadingFilter.value = false
}
const currentRoute = useRoute()
watch(
    () => currentRoute.query,
    (newValue: string) => {
      if (newValue) {
        const eleMenu: any = document.querySelector('.menu.menu-bar')
        if (eleMenu) {
          setTimeout(() => {
            eleMenu.classList.remove('activeMenu')
          }, 200)
          setTimeout(() => {
            eleMenu.classList.add('activeMenu')
          }, 500)
        }
      }
    }
)

const settings = inject('globalSettings')
const {generateLangSwitch} = useGenerateUrl(settings)
const lang_switch = generateLangSwitch('products', useRoute().params.langCode)
const langCode = useRoute().params.langCode

useDirectusCollectionSeo('products_index', {})
onMounted(() => {
  store.lang_switch = lang_switch
  const convertData = Object.keys(route.query).reduce((result: any, key) => {
    let value
    if (key === 'benefit') {
      value = route.query[key].split('&')
    } else if (key === 'text') {
      value = route.query[key]
    } else if (key === 'price_range_object') {
      value = route.query[key] ? JSON.parse(route.query[key]) : route.query[key]
    } else {
      value = route.query[key].split(',')
    }
    result[key] = value

    return result
  }, {})
  fetchProds(convertData)
})
</script>
<style scoped>
.bg-wrapper {
  background: radial-gradient(247.42% 100.06% at 32.37% 100%, #ffa17d 5.03%, #ff8050 42.75%, #ed5a22 100%);
}
</style>
