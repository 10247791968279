<template>
  <section class="relative w-full">
    <div class="absolute inset-0 z-1 bg-wrapper overflow-hidden">
      <div class="relative w-full h-full">
        <div class="absolute -bottom-20 -left-20 h-full">
          <img src="/images/circle/hinh2.svg" class="object-cover w-full h-full" alt="Banner Pattern"/>
        </div>
        <div class="hidden md:block absolute top-30 -right-100 h-full">
          <img src="/images/circle/hinh10.svg" class="object-cover w-full h-full" alt="Banner Pattern"/>
        </div>
      </div>
    </div>

    <div
        data-aos="fade-right"
        data-aos-once="true"
        class="container p-10 lg:py-20 relative z-3 flex gap-5 lg:gap-10 flex-col lg:flex-row items-center"
    >
      <div class="lg:max-w-4/10 w-full">
        <GlobalHeading
            optKeyTitle="productPage.titleBannerBottom"
            optKeySubtitle="productPage.subTitleBannerBottom"
            subTitle="Công cụ phân tích nhu cầu"
            title="Ứng dụng IZIon24 là giải pháp bảo hiểm thông minh chính thức ra mắt vào tháng 5/2022, với việc áp dụng những công nghệ hiện đại nhất."
            light
            sizeTitle="!text-xl !leading-2xl !lg:text-30px !lg:leading-12"
        ></GlobalHeading>
        <button-primary
            :title="t('DISCOVER_NOW')"
            option
            class="mt-5 lg:mt-14"
            optionKey="productpage.buttonBanner"
            optionValue="productpage.buttonBannerValue"
            :click-tracking-handler="useEventTracking(INSURANCE_ADVISE_EVENT_ID, INSURANCE_ADVISE_KEY)"
        >
        </button-primary>
      </div>
      <div data-aos="fade-left" data-aos-once="true" class="lg:max-w-6/10 w-full aspect-180/110">
        <v-image
            :width="1500"
            option_key="productPage.imageBannerBottom"
            class="w-full h-full object-cover rounded-15px"
        >
        </v-image>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import {INSURANCE_ADVISE_EVENT_ID, INSURANCE_ADVISE_KEY, useEventTracking} from "../../../composables/ackee/event";

const {t} = useI18n()
</script>

<style scoped>
.bg-wrapper {
  background: radial-gradient(247.42% 100.06% at 32.37% 100%, #ffa17d 5.03%, #ff8050 42.75%, #ed5a22 100%);
}
</style>
