<template>
  <div
      :class="!showMoreCategorires ? 'lg:mb-6 ' : 'lg:mb-6 '"
      class="flex flex-col lg:flex-row items-center mb-5 gap-6 lg:gap-0"
  >
    <div
        data-aos="fade-up"
        data-aos-once="true"
        class="flex flex-col lg:flex-row items-center w-full divide-x-none divide-y lg:divide-x lg:divide-y-none divide-black/10 lg:divide-black-30 rounded-2xl lg:rounded-50px bg-white bg-shadow py-0 lg:py-3 shadow-lg lg:shadow-none b-1 b-bw-08 b-solid lg:b-none z-10 lg:z-100 lg:h-14 px-2"
    >
      <div class="relative style-select-products vs__selected !bg-white !b-0 !m-0 search-select-product">
        <input
            @blur="clearText = true"
            @focus="clearText = false"
            type="text"
            id="text-filter-product"
            :placeholder="$t('FIELD_PRODUCT2')"
            name="text"
            class="w-full py-1px"
            v-model="titleProduct"
            autocomplete="off"
        />
        <p
            v-if="titleProduct.length > 0 && clearText"
            @click="titleProduct = ''"
            class="absolute top-1/2 transform -translate-1/2 right-1 i-custom-close text-bw-15 w-5 h-5 cursor-pointer hover:text-bw-15/80"
        ></p>
      </div>

      <WrapperSelect
          v-model="insuranceFee"
          :dataFilter="dataSearch?.custom_terms?.premium_range"
          :placeholder="$t('INSURANCE_FEES')"
      />
      <WrapperSelect v-model="company" :dataFilter="dataSearch?.carries" :placeholder="$t('COMPANY')"/>

      <!-- Mobile -->
      <Transition name="nested">
        <div
            v-if="showMoreCategorires"
            class="lg:hidden flex flex-col lg:flex-row items-center w-full divide-x-none divide-y lg:divide-x lg:divide-y-none divide-black/10 lg:divide-black-30 bg-white rounded-b-2xl b-2 b-bw-08 b-solid"
        >
          <WrapperSelect
              v-model="insuranceDirectory"
              :dataFilter="dataSearch?.products_category"
              :placeholder="$t('INSURANCE_DIRECTORY')"
          />
          <WrapperSelect
              v-model="object"
              :dataFilter="dataSearch?.custom_terms?.object"
              :placeholder="$t('SUBJECTS_OF_APPLICATION')"
          />
          <WrapperSelect
              v-model="utilities"
              :dataFilter="dataSearch?.custom_terms?.utilities"
              :placeholder="$t('UTILITIES')"
          />

          <WrapperSelect
              v-model="benefit"
              :dataFilter="dataSearch?.benefits"
              position-right
              :placeholder="$t('INSURANCE_BENEFITS')"
          />
        </div>
      </Transition>
    </div>
    <div data-aos="fade-up" data-aos-once="true" class="w-full lg:w-82.5 xl:w-108 flex items-center h-14">
      <div
          @click="showMoreCategorires = !showMoreCategorires"
          class="btn-effect flex items-center justify-center gap-5 rounded-50px bg-primary pl-5 pr-4 py-3 xl:py-4.5 w-full hover:bg-primary/90 ml-2 h-full cursor-pointer"
      >
        <p class="font-semibold text-sm xl:text-15px line-clamp-1 text-white text-center">
          {{ showMoreCategorires ? $t('HIDE_MORE_FILTERS') : $t('MORE_FILTERS') }}
        </p>
        <p
            :class="showMoreCategorires ? 'rotate-180' : ''"
            class="i-custom-chevron-down text-white text-base transform duration-200 mt-0"
        ></p>
      </div>
      <div
          @click="
                    ;(insuranceFee = ''),
                        (insuranceDirectory = ''),
                        (company = ''),
                        (benefit = ''),
                        (object = ''),
                        (utilities = '')
                "
          class="cursor-pointer flex flex-col items-center justify-center p-2 lg:p-1 mr-auto lg:mr-0"
      >
        <span class="i-mdi:refresh text-xl text-primary"></span>
      </div>
    </div>
  </div>
  <!-- Desktop -->
  <Transition name="nested">
    <div
        v-if="showMoreCategorires"
        class="hidden relative lg:flex flex-col lg:flex-row items-center gap-3 lg:gap-0 mb-5 lg:mb-16 z-10"
    >
      <div
          class="flex flex-col lg:flex-row items-center w-full divide-x-none divide-y lg:divide-x lg:divide-y-none divide-black/10 lg:divide-black-30 rounded-2xl lg:rounded-50px bg-white bg-shadow py-0 lg:py-3 shadow-lg lg:shadow-none b-1 b-bw-08 b-solid lg:b-none z-100 lg:h-14 px-2"
      >
        <WrapperSelect
            v-model="insuranceDirectory"
            :dataFilter="dataSearch?.products_category"
            :placeholder="$t('INSURANCE_DIRECTORY')"
        />
        <WrapperSelect
            v-model="object"
            :dataFilter="dataSearch?.custom_terms?.object"
            :placeholder="$t('SUBJECTS_OF_APPLICATION')"
        />
        <WrapperSelect
            v-model="utilities"
            :dataFilter="dataSearch?.custom_terms?.utilities"
            :placeholder="$t('UTILITIES')"
        />

        <WrapperSelect
            v-model="benefit"
            :dataFilter="dataSearch?.benefits"
            position-right
            :placeholder="$t('INSURANCE_BENEFITS')"
        />
      </div>
    </div>
  </Transition>
</template>

<script setup lang="ts">
import {ref, watch} from 'vue'
import {useProducts} from '../../../composables/products/index'
import WrapperSelect from './WrapperSelect.vue'

defineProps({
  data: {
    type: Array
  }
})
const {getDataSearch} = useProducts()
const route = useRoute()
const langCode = route.params.langCode
const dataSearch: any = await getDataSearch(langCode)
const titleProduct = ref(route.query?.text ?? '')

function parseCategoryString(categoryString: any) {
  return categoryString ? categoryString.split(',').map((item: any) => parseInt(item.trim())) : []
}

function parseBenefitString(beneFitString: any) {
  return beneFitString ? beneFitString.split('&').map((item: any) => item.trim()) : []
}

const insuranceFee = ref(
    dataSearch.custom_terms?.premium_range.filter((item: any) => {
      if (!parseCategoryString(route.query?.price_range)) return
      return parseCategoryString(route.query?.price_range).length > 0
          ? parseCategoryString(route.query?.price_range).find((itemChild: any) =>
              itemChild == item?.id ? item : ''
          )
          : ''
    })
)

const insuranceDirectory = ref(
    dataSearch.products_category.filter((item: any) => {
      if (!parseCategoryString(route.query?.category)) return
      return parseCategoryString(route.query?.category).length > 0
          ? parseCategoryString(route.query?.category).find((itemChild: any) => (itemChild == item.id ? item : ''))
          : ''
    })
)
const company = ref(
    dataSearch.carries.filter((item: any) => {
      if (!parseCategoryString(route.query?.carrier)) return
      return parseCategoryString(route.query?.carrier).length > 0
          ? parseCategoryString(route.query?.carrier).find((itemChild: any) => (itemChild == item?.id ? item : ''))
          : ''
    })
)

const benefit = ref(
    dataSearch.benefits.filter((item: any) => {
      if (!parseBenefitString(route.query?.benefit)) return
      return parseBenefitString(route.query?.benefit).length > 0
          ? parseBenefitString(route.query?.benefit).find((itemChild: any) => (itemChild == item.title ? item : ''))
          : ''
    })
)

const object = ref(
    dataSearch.custom_terms?.object.filter((item: any) => {
      if (!parseCategoryString(route.query?.object)) return
      return parseCategoryString(route.query?.object).length > 0
          ? parseCategoryString(route.query?.object).find((itemChild: any) => (itemChild == item.id ? item : ''))
          : ''
    })
)
const utilities = ref(
    dataSearch.custom_terms?.utilities.filter((item: any) => {
      if (!parseCategoryString(route.query?.utilities)) return
      return parseCategoryString(route.query?.utilities).length > 0
          ? parseCategoryString(route.query?.utilities).find((itemChild: any) => (itemChild == item.id ? item : ''))
          : ''
    })
)
const showMoreCategorires = ref(false)
const emits = defineEmits(['dataSearch', 'loadingFilter', 'emptyData'])
// watch(
//     () => route.query?.category,
//     (newValue) => {
//         insuranceDirectory.value = dataSearch.products_category.filter((item: any) => {
//             return parseCategoryString(newValue).find((itemChild: any) => (itemChild == item.id ? item : ''))
//         })
//     }
// )
// watch(
//     () => route.query?.carrier,
//     (newValue) => {
//         company.value = dataSearch.carries.filter((item: any) => {
//             return parseCategoryString(newValue).find((itemChild: any) => (itemChild == item?.id ? item : ''))
//         })
//     }
// )

const insuranceFeeValues = ref()
const categoryValues = ref()
const companyValues = ref()
const objectValues = ref()
const utilitiesValues = ref()
const benefitValues = ref()
const clearText = ref(false)
watchDebounced(
    () => titleProduct.value,
    (newValue) => {
      useRouter().push({
        path: route.path,
        query: {
          text: titleProduct.value,
          carrier: companyValues.value,
          category: categoryValues.value,
          object: objectValues.value,
          utilities: utilitiesValues.value,
          benefit: benefitValues.value,
          price_range: insuranceFeeValues.value,
          price_range_object: insuranceFee.value.length > 0 ? JSON.stringify(insuranceFee.value) : ''
        }
      })
      emits('dataSearch', {
        text: titleProduct.value,
        carrier: companyValues.value,
        category: categoryValues.value,
        object: objectValues.value,
        utilities: utilitiesValues.value,
        benefit: benefitValues.value,
        price_range: insuranceFee.value,
        lang: langCode
      })
    },
    {debounce: 500}
)
watch(
    () => [insuranceFee.value, insuranceDirectory.value, company.value, object.value, utilities.value, benefit.value],
    ([newInsuranceFee, newInsuranceDirectory, newCompany, newObject, newUtilities, newBenefit]) => {
      insuranceFeeValues.value =
          newInsuranceFee && newInsuranceFee.length > 0 ? newInsuranceFee.map((item: any) => item?.id).join(',') : ''
      categoryValues.value =
          newInsuranceDirectory && newInsuranceDirectory.length > 0
              ? newInsuranceDirectory.map((item: any) => item?.id).join(',')
              : ''
      companyValues.value =
          newCompany && newCompany.length > 0 ? newCompany.map((item: any) => item?.id).join(',') : ''
      objectValues.value = newObject && newObject.length > 0 ? newObject.map((item: any) => item?.id).join(',') : ''
      utilitiesValues.value =
          newUtilities && newUtilities.length > 0 ? newUtilities.map((item: any) => item?.id).join(',') : ''
      benefitValues.value =
          newBenefit && newBenefit.length > 0 ? newBenefit.map((item: any) => item?.title).join('&') : ''
      useRouter().push({
        path: route.path,
        query: {
          text: titleProduct.value,
          carrier: companyValues.value,
          category: categoryValues.value,
          object: objectValues.value,
          utilities: utilitiesValues.value,
          benefit: benefitValues.value,
          price_range: insuranceFeeValues.value,
          price_range_object: newInsuranceFee && newInsuranceFee.length > 0 ? JSON.stringify(newInsuranceFee) : ''
        }
      })
      emits('dataSearch', {
        text: titleProduct.value,
        carrier: newCompany && newCompany.length > 0 ?
            newCompany.map((item: any) => item?.id) : '',
        category: newInsuranceDirectory && newInsuranceDirectory.length > 0 ?
            newInsuranceDirectory.map((item: any) => item?.id) : '',
        object: newObject && newObject.length > 0 ?
            newObject.map((item: any) => item?.id) : '',
        utilities: newUtilities && newUtilities.length > 0 ?
            newUtilities.map((item: any) => item?.id) : '',
        benefit: newBenefit && newBenefit.length > 0 ?
            newBenefit.map((item: any) => item?.title) : '',
        price_range: newInsuranceFee,
        lang: langCode
      })
    }
)

onMounted(() => {
  if (
      (object.value && object.value.length > 0) ||
      (utilities.value && utilities.value.length > 0) ||
      (benefit.value && benefit.value.length > 0) ||
      (insuranceDirectory.value && insuranceDirectory.value.length > 0)
  ) {
    showMoreCategorires.value = true
  }
})
</script>

<style>
@import 'vue-select/dist/vue-select.css';

.search-select-product {
  @apply w-full lg:w-4/12 px-4  !py-3 !lg:py-0;
}

.style-select-products .vs__search::placeholder,
.search-select-product input::placeholder {
  @apply text-black/30 text-sm font-medium;
}

.nested-enter-active,
.nested-leave-active {
  transition: all 0.25s ease;
}

/* delay leave of parent element */
.nested-leave-active {
}

.nested-enter-from,
.nested-leave-to {
  transform: translateY(30px);
  opacity: 0;
}

.bg-shadow {
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
}

.vs__deselect {
  @apply absolute top-1/2 transform -translate-y-1/2 right-1 hover:bg-gray-300 bg-gray-200 rounded-full w-4 h-4 flex flex-col items-center justify-center;
}

.vs__deselect > svg {
  @apply transform scale-80;
}

.checkbox-custom input[type='checkbox'].popup {
  border: var(--borderWidth) solid #d2d2d2;
}

.checkbox-custom input[type='checkbox'].popup:checked {
  border: var(--borderWidth) solid #ff8050;
}

/*
Transition select
*/

.nestedSelect-enter-active,
.nestedSelect-leave-active {
  transition: all 0.15s ease;
}

/* delay leave of parent element */
.nestedSelect-leave-active {
}

.nestedSelect-enter-from,
.nestedSelect-leave-to {
  transform: translateY(60px);
  opacity: 0;
}
</style>
