<template>
  <div class="">
    <div class="relative bg-white rounded-md w-full bg-shadow pr-0.5 pt-1">
      <div class="max-h-[40vh] lg:max-h-[60vh] overflow-auto custom-scrollbar">
        <div class="relative px-5 py-3 flex-lazy-row-center pr-1 border-b">
          <input type="text" class="w-full h-full pl-8" v-model="searchTitle" :placeholder="$t('SEARCH')"/>
          <span
              v-if="!searchLoading"
              class="flex-none absolute left-5 i-ic:twotone-search text-black/30"
          ></span>
          <div v-else class="absolute loaderForm left-5 flex-none"></div>
        </div>
        <!-- Selected Data -->
        <div v-if="currentValueTemp && currentValueTemp.length > 0">
          <div class="px-5 py-3.5 bg-[#F2F2F2] flex items-center justify-between">
            <p class="text-bw-07 font-[550] text-sm">{{ $t('SELECTED_PRODUCT') }}</p>
            <p @click="currentValueTemp = []" class="cursor-pointer text-primary font-[550] text-sm">
              {{ $t('UNSELECTED_PRODUCT') }}
            </p>
          </div>
          <div class="px-5 py-3.5 space-y-7">
            <label
                v-for="(item, index) in currentValueTemp"
                :key="item.id"
                class="flex items-start gap-3 checkbox-custom cursor-pointer"
            >
              <input
                  type="checkbox"
                  name=""
                  :value="item"
                  v-model="currentValueTemp"
                  checked
                  class="select-product-item flex-none mt-3px cursor-pointer"
              />
              <p class="text-sm font-medium text-bw-07 hover:text-bw-07/70">
                {{ item.title }}
              </p>
            </label>
          </div>
        </div>
        <!-- All Data  -->
        <div class="">
          <div class="px-5 py-3.5 bg-[#F2F2F2] flex items-center justify-between">
            <p class="text-bw-07 font-[550] text-sm">{{ $t('SELECTION_PRODUCT_LIST') }}</p>
            <p
                v-if="dataRender?.length > 0"
                @click="currentValueTemp = dataUpdate"
                class="cursor-pointer text-primary font-[550] text-sm"
            >
              {{ $t('CHOOSE_ALL_PRODUCT') }}
            </p>
          </div>
          <div v-if="dataRender && dataRender.length > 0" class="px-5 py-3.5 space-y-7">
            <label
                v-for="item in dataRender"
                :key="item.id"
                :for="'item-' + item.title + '-' + item.id"
                class="flex items-start gap-3 checkbox-custom cursor-pointer"
            >
              <input
                  :id="'item-' + item.title + '-' + item.id"
                  type="checkbox"
                  name=""
                  :value="item"
                  v-model="currentValueTemp"
                  class="select-product-item flex-none mt-3px cursor-pointer"
              />
              <p class="text-sm font-medium text-bw-07 hover:text-bw-07/70">
                {{ item.title }}
              </p>
            </label>
          </div>
          <p v-else class="text-black/30 p-5">
            {{ $t('NO_DATA_PRODUCT') }}
          </p>
        </div>
      </div>
      <div class="flex items-center justify-end gap-3 px-5 py-3.5 text-sm font-medium border-t">
        <button
            @click="$emit('closeDropdown', false)"
            class="py-2.5 px-6 rounded-lg text-bw-07 hover:bg-bw-08 hover:text-white border border-bw-04 hover:border-bw-08 duration-200"
        >
          {{ $t('CANCEL_DATEPICKER') }}
        </button>
        <button @click="searchFilter" class="py-2.5 px-6 rounded-lg bg-primary text-white btn-effect">
          {{ $t('APPLY_FILTER') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
const props: any = defineProps({
  data: {
    type: Array
  }
})
const emit = defineEmits(['closeDropdown'])
const currentValueSelected: any = defineModel()
const currentValueTemp: any = ref(currentValueSelected.value || [])
const searchTitle = ref()
const searchLoading = ref(false)
const dataUpdate = ref(props.data)
const dataRender = computed(() => {
  if (currentValueTemp.value.length > 0 && props?.data.length > 0) {
    return dataUpdate.value.filter(
        (item: any) => !currentValueTemp.value.map((child: any) => child.id).includes(item.id)
    )
  }
  return dataUpdate.value
})
watch(
    () => currentValueSelected.value,
    (newValue) => {
      currentValueTemp.value = newValue
    }
)
watchDebounced(
    () => searchTitle.value,
    (newValue) => {
      searchLoading.value = true
      if (!newValue) {
        setTimeout(() => {
          searchLoading.value = false
        }, 300)
        return (dataUpdate.value = props.data)
      }

      setTimeout(() => {
        dataUpdate.value = props.data.filter((item: any) => {
          return item.title.toLowerCase().includes(newValue.toLowerCase())
        })
        searchLoading.value = false
      }, 300)
    },
    {debounce: 500}
)
const searchFilter = () => {
  currentValueSelected.value = currentValueTemp.value
  setTimeout(() => {
    emit('closeDropdown', false)
  }, 100)
}
</script>

<style scoped>
input[type='checkbox'].select-product-item {
  @apply !b-primary rounded;
}

.checkbox-custom input[type='checkbox'].select-product-item:checked {
  @apply bg-primary;
}

.checkbox-custom input[type='checkbox']:before,
.checkbox-custom input[type='checkbox']:after {
  @apply bg-white;
}

.bg-shadow {
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
}
</style>
